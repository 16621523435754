<template>
  <transition name="appMain" mode="out-in">
    <router-view />
  </transition>
</template>

<script>
export default {
  name: "SysMgt",
};
</script>

<style>
.appMain-leave-active,
.appMain-enter-active {
  transition: all 0.5s;
}

.appMain-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.appMain-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
